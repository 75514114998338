import { z } from "zod";

export enum TemplateStatus {
  Inactive = "inactive",
  Testing = "testing",
  Published = "published",
}

export enum TemplatePermission {
  Public = "public",
  Private = "private",
  Account = "account",
}

const idType = z.union([z.string(), z.number()]);

export const canvasTemplateTagSchema = z.object({
  id: idType.optional(),
  name: z.string().min(1),
  templates: z.array(idType).default([]),
  exampleTemplates: z.array(idType).default([]).nullable(),
});

export const canvasCreateTemplateSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  tags: z.array(canvasTemplateTagSchema),
  permission: z.nativeEnum(TemplatePermission).default(TemplatePermission.Public),
  previewImageData: z.string(),
  thumbnailImageData: z.string(),
  data: z.record(z.string(), z.any()),
});

export const canvasUpdateTemplateMinimalSchema = z.object({
  id: idType,
  name: z.string().min(1),
  description: z.string().min(1),
  tags: z.array(z.object({ id: idType })),
  permission: z.nativeEnum(TemplatePermission).default(TemplatePermission.Public),
});

export const canvasUpdateTemplateSchema = z.object({
  id: idType,
  name: z.string().min(1),
  description: z.string().min(1),
  tags: z.array(z.object({ id: idType })),
  status: z.nativeEnum(TemplateStatus),
  isPaid: z.boolean(),
  alias: z.string().nullable(),
  thumbnailImageData: z.string().optional(),
  metadata: z.record(z.any()).optional(),
  inMarketplace: z.boolean().default(false),
  permission: z.nativeEnum(TemplatePermission).default(TemplatePermission.Public),
});

const canvasDataSchema = z.object({
  elements: z.record(z.string(), z.any()),
  metadata: z.record(z.string(), z.any()).nullish(),
});

export const canvasTemplateMinimalSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  previewImageURL: z.string().nullable(),
  thumbnailImageURL: z.string().nullable(),
  upgradeRequired: z.boolean(),
  metadata: z.record(z.any()).nullish(),
  alias: z.string().nullable(),
  isOwner: z.boolean().optional(),
  isAccountTemplate: z.boolean().optional(),
  permission: z.nativeEnum(TemplatePermission).default(TemplatePermission.Public),
});

export const canvasTemplateFullSchema = canvasTemplateMinimalSchema.extend({
  data: canvasDataSchema,
});

export const canvasTemplateAdminSchema = canvasTemplateMinimalSchema.merge(
  z.object({
    tags: z.array(z.number()).default([]),
    preview_orders: z.array(z.union([z.number(), z.null()])).default([]),
    status: z.nativeEnum(TemplateStatus),
    isPaid: z.boolean(),
    alias: z.string().nullable(),
  })
);

export const canvasExmapleTemplateSchema = z.array(z.object({
  templateId: z.number(),
  tagId: z.number(),
  previewOrder: z.number(),
}))

export function mapTemplateFromDB(object: any, schema: any = canvasTemplateMinimalSchema) :  CanvasTemplateMinimal {
  return schema.parse({
    id: `${object.id}`,
    name: object.name,
    description: object.description,
    previewImageURL: object.preview_image_url,
    thumbnailImageURL: object.thumbnail_url,
    upgradeRequired: object.upgrade_required ?? true,
    metadata: object.metadata,
    tags: object.tags,
    preview_orders: object.preview_orders,
    status: object.status,
    isPaid: object.is_payed,
    alias: object.alias,
    isOwner: object.is_owner,
    isAccountTemplate: object.is_account_template,
    permission: object.permission,
  });
}

export const BlankCanvasId = "blank";
export const OrgChartCanvasId = "org-chart-integration";

export const BlankCanvasTemplate: CanvasTemplate = {
  id: BlankCanvasId,
  name: "New Canvas",
  description: "A blank canvas",
  previewImageURL: null,
  thumbnailImageURL: `/images/template-thumbnails/plus.svg`,
  upgradeRequired: false,
  data: {
    elements: {},
    metadata: {},
  },
  metadata: null,
  alias: "blank",
  permission: TemplatePermission.Private,
};

export type CanvasTagId = z.infer<typeof idType>;
export type CanvasTemplateTag = z.infer<typeof canvasTemplateTagSchema>;
export type CanvasTemplateForm = z.infer<typeof canvasCreateTemplateSchema>;
export type CanvasUpdateTemplateForm = z.infer<typeof canvasUpdateTemplateSchema>;
export type canvasUpdateTemplateMinimalForm = z.infer<typeof canvasUpdateTemplateMinimalSchema>;
export type CanvasTemplate = z.infer<typeof canvasTemplateFullSchema>;
export type CanvasTemplateMinimal = z.infer<typeof canvasTemplateMinimalSchema>;
export type CanvasTemplateAdmin = z.infer<typeof canvasTemplateAdminSchema>;
export type CanvasExampleTemplates = z.infer<typeof canvasExmapleTemplateSchema>;
