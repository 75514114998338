import { Plan } from "../../consts";
import { z } from "zod";

export const billingIntervalSchema = z.union([z.literal('year'), z.literal('month'), z.null()]);

export const stripePortalConfigSchema = z.object({
  type: z.union([z.literal("admin"), z.literal("upgrade"), z.literal("update-payment")]),
  planId: z.nativeEnum(Plan).optional(),
  interval: billingIntervalSchema.optional(),
  openInNewTab: z.boolean().optional(),
});

export const planData = z.object({
  name: z.string(),
  price: z.number(),
  interval: billingIntervalSchema,
  trialDays: z.number()
});

export type StripePortalConfig = z.infer<typeof stripePortalConfigSchema>;

export type PlanData = z.infer<typeof planData>;

export type BillingInterval = z.infer<typeof billingIntervalSchema>;