import { atom } from "jotai";

/* DO NOT ABUSE THIS!! Don't put atoms here by default! Try to put them always in a distinct file.
Only if it really doesn't make sense and they are truly "general", put them here.
*****/

export const stageRefAtom = atom<any>(null);

export const miniStageRefAtom = atom<any>(null);

export const layerRefAtom = atom<any>(null);

export const transformerRefAtom = atom<any>(null);

export const syncServiceAtom = atom<any>(null);

export const isBusyAtom = atom<boolean>(false);

export const filesSignedUrlsAtom = atom<Record<string, string>>({});

export const cordAuthAtom = atom<string | null>(null);

export const errorMessageAtom = atom<string | null>(null);

export const isBannerNotificationOnAtom = atom<boolean>(false);

export const isUserDisconnectedAtom = atom<boolean>(false);

export enum HtmlDialogDisplay {
  None,
  InviteToAccount,
  InviteToBoard,
  EditSeats,
  ShowUpgradeModal,
  OpenTaskCard,
}

export const htmlDisplayAtom = atom(HtmlDialogDisplay.None);


