import { Action, DispatchEvent } from "./actions";
import LoadedState from "./loadedState";
import { User } from "shared/datamodel/schemas/user";
import { AppState } from "shared/datamodel/schemas/app-state";

export default function reducer(state: AppState, event: DispatchEvent): AppState {
  const { payload, type } = event;
  switch (type) {
    case Action.UpdatedUser:
      const user = payload;
      const account = payload.account;
      return {
        ...state,
        isLoggedIn: true,
        userState: LoadedState.finished,
        user,
        account,
      };

    case Action.FailedLoadingUser:
      return {
        ...state,
        userState: LoadedState.failed,
      };

    case Action.LoadingBoards:
      return {
        ...state,
        boardsState: LoadedState.loading,
      };

    case Action.FailedLoadingBoards:
      return {
        ...state,
        boardsState: LoadedState.failed,
      };

    case Action.UpdatedBoards:
      return {
        ...state,
        boardsState: LoadedState.finished,
        boards: payload,
      };
    case Action.LoadedSpecificBoard: {
      const boards = [...state.boards];
      if (payload.accountId === state.account?.id) {      
        boards.push(payload);
        return {
          ...state,
          boards,
        };
      }
    }
    case Action.DeleteBoard: {
      const boards = [...state.boards];
      const index = boards.findIndex((b) => b.documentId === payload);
      if (index >= 0) {
        boards.splice(index, 1);
      }
      return {
        ...state,
        boards,
      };
    }
    case Action.FailedDeleteBoard: {
      const boards = [...state.boards];
      boards.unshift(payload);
      return {
        ...state,
        boards,
      };
    }
    case Action.EditedBoard:
    case Action.FailedEditBoard: {
      const boards = [...state.boards];
      const index = boards.findIndex((b) => b.documentId === payload.documentId);
      if (index >= 0) {
        boards[index] = payload;
      }
      return {
        ...state,
        boards,
      };
    }
    case Action.CreatedBoard: {
      const boards = [...state.boards];
      boards.unshift(payload);
      return {
        ...state,
        boards,
      };
    }
    case Action.PromotionLoaded:
      return {
        ...state,
        activePromotion: payload,
      };

    case Action.PricingPromotionLoaded:
      return {
        ...state,
        pricingPromotion: payload,
      };

    case Action.SetAccountReps:
      return {
        ...state,
        accountReps: payload,
      };

    case Action.LoadingTeams:
      return {
        ...state,
      };

    case Action.UpdatedTeams:
      return {
        ...state,
        teams: payload,
      };

    case Action.CreatedTeam: {
      const teams = [...state.teams];
      teams.push(payload);
      return {
        ...state,
        teams,
      };
    }
    
    case Action.EditedTeam:
    case Action.FailedEditTeam: {
      const teams = [...state.teams];
      const index = teams.findIndex((t) => t.documentId === payload.documentId);
      if (index >= 0) {
        teams[index] = payload;
      }
      return {
        ...state,
        teams,
      };
    }

    case Action.FailedSelectTeam: {
      const teams = [...state.teams];
      const index = teams.findIndex((t) => t.documentId === payload.documentId);
      if (index >= 0) {
        teams[index] = payload;
      }
      return {
        ...state,
        teams,
      };
    }

    case Action.DeleteTeam: {
      const teams = [...state.teams];
      const index = teams.findIndex((t) => t.documentId === payload);
      if (index >= 0) {
        teams.splice(index, 1);
      }
      return {
        ...state,
        teams,
      };
    }
    case Action.CreatedProject:{
      const teams = [...state.teams];
      const teamIndex = teams.findIndex((t) => t.id === payload.teamId);
      if(teams[teamIndex].projects) {
        !teams[teamIndex].projects?.map(p => p.id).includes(payload.id) && teams[teamIndex].projects?.push(payload)
      } else {

        teams[teamIndex].projects = [payload]
      }
      return {
        ...state,
        teams,
      };
    }
    case Action.EditedProject:
    case Action.FailedEditProject: {
      const teams = [...state.teams];
      const teamIndex = teams.findIndex((t) => t.id === payload.teamId);
      const teamToEdit = teams[teamIndex]
      const projectIndex = teamToEdit.projects?.findIndex((p) => p.documentId === payload.documentId);
      if (projectIndex && projectIndex >= 0 && teamToEdit.projects) {
        teamToEdit.projects[projectIndex] = payload;
        teams[teamIndex] = teamToEdit;
      }
      return {
        ...state,
        teams,
      };
    }
    case Action.DeleteProject: {
      const teams = [...state.teams];
      const teamIndex = teams.findIndex((t) => t.id === payload.teamId);
      const teamToEdit = teams[teamIndex]
      const projectIndex = teamToEdit.projects?.findIndex((p) => p.documentId === payload.documentId);
      if (projectIndex && projectIndex >= 0 && teamToEdit.projects) {
        teamToEdit.projects.splice(projectIndex, 1);
        teams[teamIndex] = teamToEdit;
      }
      return {
        ...state,
        teams,
      };
    }

    default:
      return state;
  }
}
